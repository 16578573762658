const app = new Vue({
    el: '#login',
    data() {
        return {
            email: '',
            password: '',
            form: {
                submitted: false,
                errors: [],
                success: false
            },
        }
    },
    methods: {
        async submit() {
            const validates = await this.$refs.observer.validate();
            if (!validates) {
                this.submitted = false;
                return;
            }
            $('#login form')[0].submit();
        }
    },
    components: {
    },
    mounted() {
    }
});
